@import url('//at.alicdn.com/t/font_1551649_8g9vwpxs9g4.css');

html,body {
  margin: 0;
  padding: 0;
  background: #f3f3f3;
  font-size: 14px;
  min-width: 1200px;
}

