.tableComponent {
    margin: 20px auto;
    width: 90%;
    min-width: 1200px;
    padding: 10px 20px;
    background: #fff;
    box-shadow:  0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.userHandle {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}

.export {
    margin-right: 16px;
}